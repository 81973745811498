import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import AuthorBio from './author-bio';

import bios from '../libs/data/authors.json';

const AuthorBios = ({ authors }) => {
  const query = graphql`
    {
      authors: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)/" }
          relativeDirectory: { eq: "auteurs" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
  `;

  const authorBios = bios.filter((item) => authors.includes(item.name));
  if (!authorBios.length) {
    return '';
  }

  const BiosContainer = styled.div`
    margin-top: 2rem;
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        return (
          <BiosContainer>
            {authorBios.map((bio, index) => {
              const edge = data.authors.edges.find(
                (edge) => edge.node.name === bio.img,
              );
              return (
                <AuthorBio
                  key={index}
                  name={bio.name}
                  img={edge.node.childImageSharp.fixed.src}
                  description={bio.description}
                />
              );
            })}
          </BiosContainer>
        );
      }}
    ></StaticQuery>
  );
};

export default AuthorBios;
