import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import Nav from '../components/nav';
import ReadLink from '../components/read-link';
import styled from '@emotion/styled';
import device from '../helpers/breakpoints';
import Footer from '../components/footer';
import Moment from 'react-moment';
import 'moment/locale/nl';
import Authors from '../components/authors';
import AuthorBios from '../components/author-bios';

export const query = graphql`
  query($slug: String!) {
    mdx(fileAbsolutePath: { regex: $slug }) {
      frontmatter {
        title
        authors
        date
      }
      body
    }
    site {
      siteMetadata {
        name
        title
        description
      }
    }
  }
`;

const BodyContainer = styled.div`
  max-width: 728px;
  padding: 0 1rem;
  margin: 8rem auto 4rem;

  @media ${device.tablet} {
    padding: 0 2rem;
    max-width: 960px;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.022em;
  }

  h2 {
    font-size: 26px;
    line-height: 1.18;
    letter-spacing: -0.022em;
    margin-top: 1.72em;
    
    + p {
      margin-top: 0.46em;
    }
  }

  p {
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.004em;
    margin-top: 2em;
  }
`;

const MetaDataContainer = styled.div`
  span {
    display: block;
    font-size: 0.8rem;
    letter-spacing: -0.004em;
    color: rgba(0, 0, 0, 0.54);
    margin-top: 0.5em;
  }
`;

const PostTemplate = ({ data: { mdx: post, site } }) => {
  return (
    <Layout metadata={site.siteMetadata}>
      <Nav Root="/" Fixed />
      <BodyContainer>
        <h1>{post.frontmatter.title}</h1>
        <MetaDataContainer>
          <span>
            Geschreven door <Authors authors={post.frontmatter.authors}/> op{' '}
            <Moment format="DD MMMM YYYY">{post.frontmatter.date}</Moment>
          </span>
        </MetaDataContainer>
        <MDXRenderer>{post.body}</MDXRenderer>
        <AuthorBios authors={post.frontmatter.authors} />
        <ReadLink to="/#artikels-anchor">&larr; Terug</ReadLink>
      </BodyContainer>
      <Footer />
    </Layout>
  );
};

export default PostTemplate;
