import React from 'react';
import styled from '@emotion/styled';

const AuthorBio = ({ name, img, description }) => {
  const BioContainer = styled.div`
    display: block;
    min-height: 120px;
    margin: 0 0 1rem 0;
  `;

  const Picture = styled.img`
    max-width: 100%;
    max-height: 120px;
    float: left;
    margin-right: 1rem;
  `;

  const Description = styled.span`
    font-size: 0.8rem;
  `;

  return (
    <BioContainer>
      <Picture src={img} alt={name} />
      <Description>{description}</Description>
    </BioContainer>
  );
};

export default AuthorBio;
